import React from "react";
import Sectiontitle from "../../components/section-title";
import { Container, Grid, Box, Typography, Link } from "@mui/material";
import './expertise.css';
import useExpertise from "../../hooks/useexpertise";
import { GALLERY_IMAGES } from "../../constants";

function Expertise() {
  const { photo, loading, error } = useExpertise();

  if (loading) return <p></p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Box className="home-expertise" sx={{ py: 13 }}>
      <Container maxWidth="xl" className="container">
        <Sectiontitle
          data={{
            mainTitle: "Expertise",
            subTitle: "Need any help with these technologies? Give us a buzz",
          }}
        />
        <Grid
          className="expertise-list"
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
        >
          {photo.map((item, index) => {
            const images = JSON.parse(item.image_gallery);
            return images.map((img, imgIndex) => (
              <Grid item key={`${index}-${imgIndex}`} className="block">
                <Link href={item.link} target="_blank">
                  <img src={`${GALLERY_IMAGES}${img.path}`} alt={img.alt_text} />
                  <Typography component="p" variant="h5">{item.title}</Typography>
                </Link>
              </Grid>
            ));
          })}
        </Grid>
      </Container>
    </Box>
  );
}

export default Expertise;
