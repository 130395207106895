// import React from "react";
// import { Container, Grid, Box } from "@mui/material";
// import ServiceCardList from "./services-card-list";
// import Servicecardlist from  "../../lists/list-service";
// import { ListItem } from "@mui/material";
// import { useMediaQuery } from "@mui/material";
// import { Helmet } from "react-helmet";
// import useServices from "../../hooks/useservices";
// function Services() {
//   const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const { service, loading, error } = useServices(); 

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <Box sx={{ py: 13 }}>
//                 <Helmet>
//                     <title>Our Services | F9 Technologies</title>
//                     <meta name="keywords" content="Best services provided by f9 in Vizag, professional f9 IT services in Vizag, F9 Mobile development service in vizag, F9 graphic designers in vizag, best developers in vizag"/>
//                     <meta name="description" content="Elevate your IT infrastructure with F9's professional services tailored for Vizag, ensuring reliability and efficiency."/>
//                 </Helmet>
//       <Container maxWidth="xl" className="container">
//         <Grid
//           className="services-list"
//           container
//           spacing={5}
//           justifyContent="space-around"
//           alignItems="flex-end" // Align items to the bottom vertically
//         >
//           {service.map((item, index) => (
//             <Grid item xs={isSmallScreen ? 12 : 6} key={index} className="services-list-block">
//               <ServiceCardList 
//                 title={item.title}
//                 imageUrl={item.imageUrl}
//                 body={service.map((item, index) => (
//                   <ListItem key={index}>{item}</ListItem>
//                 ))}
//               />
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   );
// }

// export default Services;


import React from "react";
import { Container, Grid, Box } from "@mui/material";
import ServiceCardList from "./services-card-list"; // Assuming this is the component rendering the card for each service
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";
import useServices from "../../hooks/useservices";
import { SERVICE_IMAGES } from "../../constants";
import '../../styles/styles.css';
function Services() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { service, loading, error } = useServices(); 
  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (error) return <p>Error: {error}</p>;

  return (
    <Box sx={{ py: 13 }}>
      <Helmet>
        <title>Our Services | F9 Technologies</title>
        <meta name="keywords" content="Best services provided by f9 in Vizag, professional f9 IT services in Vizag, F9 Mobile development service in vizag, F9 graphic designers in vizag, best developers in vizag"/>
        <meta name="description" content="Elevate your IT infrastructure with F9's professional services tailored for Vizag, ensuring reliability and efficiency."/>
      </Helmet>
      <Container maxWidth="xl" className="container">
        <Grid
          className="services-list"
          container
          spacing={5}
          justifyContent="space-around"
          alignItems="flex-end"
        >
          {service.map((item, index) => (
            <Grid item xs={isSmallScreen ? 12 : 6} key={index} className="services-list-block">
              <ServiceCardList 
                title={item.title}
                imageUrl={`${SERVICE_IMAGES}${item.image}`}
                body={item.short_description}
                id={item.id}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Services;
