import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchDataFromApi } from '../services/menuapi';

// Create Context
const DataContext = createContext();

// Custom hook to access the context easily
export const useDataContext = () => useContext(DataContext);

// Provider component to manage and provide the data
export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataWithDelay = async () => {
      try {
        const result = await fetchDataFromApi('headerdata');
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        // Add a delay to ensure loading is at least 2 seconds
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    };

    fetchDataWithDelay();
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <DataContext.Provider value={{ data, loading, error }}>
      {children}
    </DataContext.Provider>
  );
};
