import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import './index.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LightbulbIcon from '@mui/icons-material/Lightbulb';


function processcate(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
}

function ProcessCard({ title = "Title", body = "Body", icons }) {
  const processcatedTitle = title;
  const processcatedBody = body;
  const icon1 = icons;
  console.log("icon-",icon1);

  return (
    
     <Card className="process-card"> 
         
         {/* <figure style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LightbulbIcon style={{ fontSize: '48px' }} />
         </figure> */}

      <CardContent>
        <Typography
          className="title"
          gutterBottom
          variant="h5"
          component="div"
          dangerouslySetInnerHTML={{ __html: processcatedTitle }}
        />

        <Typography
          component="p"
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: processcatedBody }}
        />
      </CardContent>
    </Card>
  );
}

export default ProcessCard;
