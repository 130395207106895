import React from 'react';
import { useParams } from 'react-router-dom';
import { useDataContext } from '../context/menucontext';
import Blog from '../components/blog';
import Services from '../pages/services';
import Solutions from '../pages/solutions';
import About from '../pages/about-us';
import InnerBanner from '../components/inner-banner/innerbanner';
import { Box, Container, Typography } from "@mui/material";
import { CMS_IMAGES } from '../constants';
import '../styles/styles.css';

const ItemPage = () => {
  const { slug } = useParams();
  const { data, loading, error } = useDataContext();

  if (loading) return 
  <div class="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>;
  if (error) return <p>Error: {error}</p>;

  const item = data?.find((item) => item.slug === slug);

  if (!item) return <p>Item not found!</p>;

  // Conditional rendering based on slug using if-else statements
  if (slug === 'services') {
    return <Services />;
  } else if (slug === 'solutions') {
    return <Solutions />;
  }
  else if (slug === 'about-us') {
    return <About data={data} />;
  }
  else if(slug ==='blog'){
    return <Blog />;
  }
   else {
    return (
      <>
        <InnerBanner bannerImage={item.banner_image}
        title={item.title || item.banner_title}
        tagline ={item.banner_tagline} path={CMS_IMAGES}/>
        <Box component="section">
          <Container>
            <Typography>{item?.short_description || "Coming Soon.."}</Typography>
            <Box 
              component="div" 
              
              business
            />
          </Container>
        </Box>
      </>
    );
  }
};

export default ItemPage;
