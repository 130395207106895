import React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Container, Box, Grid, Button, CardActionArea, CardActions, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import Sectiontitle from "../../components/section-title";
import { BLOGS_IMAGES } from "../../constants";
import useBlogs from "../../hooks/useblogs";

function Blog({ limit }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { blogs, loading, error } = useBlogs();

  if (loading) return <p></p>;
  if (error) return <p>Error: {error}</p>;

  // Limit the blogs based on the passed prop
  const displayedBlogs = limit ? blogs.slice(0, limit) : blogs;

  return (
    <Box sx={{ py: 13 }} className="blog">
      <Container maxWidth="xl" className="container">
        {/* Add Section Title if needed */}
        <Grid
          container
          spacing={isSmallScreen ? 0 : 5}
          justifyContent="center"
          alignItems="left"
          sx={{ pt: isSmallScreen ? 0 : 13 }}
        >
          {displayedBlogs.map((blog) => (
            <Grid item xs={isSmallScreen ? 12 : 4} key={blog.id} className="block">
              <Box className="card">
                <CardActionArea>
                  <figure>
                    <CardMedia
                      component="img"
                      height="140"
                      image={`${BLOGS_IMAGES}${blog.image}`}
                      alt="F9tech"
                    />
                  </figure>
                  <CardContent className="card-content">
                    <Typography gutterBottom variant="h5" component="div" className="title">
                      {blog.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      dangerouslySetInnerHTML={{ __html: blog.short_description }}
                    />
                    <CardActions className="card-actions">
                      <Link to={`/blog/${blog.id}`} style={{ textDecoration: "none" }}>
                        <Button size="small" variant="outlined" color="primary">
                          Read More
                        </Button>
                      </Link>
                    </CardActions>
                  </CardContent>
                </CardActionArea>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Blog;
